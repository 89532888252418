<template>
  <!-- prettier-ignore -->
  <div class="replace">
    <Modal.Header :text="$t('product.replace.title')" />
    <Modal.Content>
      <div class="replace-products">
        <div class="replace-product">
          <the-typography
            type="heading04"
            v-text="$t('product.replace.selected')"
          />
          <replace-product
            v-if="from"
            :group-id="from.groupId"
            :product-id="from.productId"
            :product="from.product"
          />
          <loading-alt v-else />
        </div>

        <div class="replace-product">
          <the-typography
            type="heading04"
            v-text="$t('product.replace.selectable')"
          />
          <replace-product
            v-if="to"
            is-selected
            :group-id="to.groupId"
            :product-id="to.productId"
            :product="to.product"
          />
          <loading-alt v-else />
        </div>
      </div>
    </Modal.Content>

    <Modal.Footer>
      <template #secondary>
        <Form.Button
          :disabled="!to || !from"
          secondary
          :text="$t('form.cancel')"
          @click="$emit('close')"
        />
      </template>

      <template #primary>
        <Form.Button
          :disabled="!to || !from"
          has-loading-indicator
          primary
          :text="$t('product.replace.confirm')"
          @click="$emit('replace', to.product)"
        />
      </template>
    </Modal.Footer>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'

import cloneDeep from 'lodash/cloneDeep'

import { BasketUtil } from '@/utils/Basket'
import { events$ } from '@/services'

import basketStore from '@/store/basket'
import usePersonSpecificProduct from '@/hooks/usePersonSpecificProduct'
import useProduct from '@/hooks/useProduct'

import * as Form from '@/components/Form'
import * as Modal from '@/components/Modal'
import LoadingAlt from '@/components/Container/LoadingAlt'
import ReplaceProduct from '@/components/Product/Replace/ReplaceProduct'

import { EVENT_PRODUCT } from '@/config/events'

// INIT
defineEmits(['close', 'replace'])
const props = defineProps({
  groupId: {
    type: String,
    required: true,
  },
  fromProductId: {
    type: String,
    required: true,
  },
  toProductId: {
    type: String,
    required: true,
  },
  personId: {
    type: String,
    required: true,
  },
})

// DATA
let from = ref(undefined)
let to = ref(undefined)

// METHODS
async function simulate(personId, fromProductId, toProductId) {
  let _basket = cloneDeep(basketStore.basket)

  const { getProductPrice } = usePersonSpecificProduct()
  const { getGroupIdFromProduct } = useProduct()

  const selectedOption = getProductPrice(personId, fromProductId)

  _basket = BasketUtil.selectProducts(
    personId,
    _basket,
    [fromProductId],
    [{ productId: toProductId, criteria: { ...selectedOption } }]
  )
  _basket = await basketStore.updateOvpBasket(_basket, { simulate: true })

  window.setTimeout(() => {
    from.value = {
      productId: props.fromProductId,
      product: _basket.persons.find(p => p.personId === personId).products.products[props.fromProductId],
      groupId: getGroupIdFromProduct(props.fromProductId),
    }
    to.value = {
      productId: props.toProductId,
      product: _basket.persons.find(p => p.personId === personId).products.products[props.toProductId],
      groupId: props.groupId,
    }
  }, 250)
}

// LIFECYCLE HOOKS
onMounted(() => {
  simulate(props.personId, props.fromProductId, props.toProductId)

  events$.emit(EVENT_PRODUCT.REPLACE_DISPLAYED)
})
</script>

<style name="mobile" scoped>
.replace-products {
  display: grid;
  gap: 50px;
}

.replace-product {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;

  .product {
    margin: 8px 0 0 0;
    border: 1px solid var(--c-primary-neutral-1);
  }
}
</style>

<style name="desktop" scoped>
@media (--md) {
  .replace-products {
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }
}
</style>
